import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Autocomplete } from "@material-ui/lab";
import { CustomDatePicker } from "components/CustomDatePicker";
import { CustomTextField } from "components/CustomTextField";
import { DispatchActionButton } from "components/DispatchActionButton";
import { EmailInput } from "components/EmailInput";
import { EMSelect } from "components/EMSelect";
import { EngineerStaffingCalendarForm } from "engineers/EngineerStaffingCalendarForm";
import { EMTimeOffForm } from "./EMTimeOffForm";
import { EMHandle, EMRole } from "msd-capacity-planning-model";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import {
  AppState,
  ReportFeedbackAction,
  REPORT_APP_FEEDBACK
} from "../redux/AppTypes";
import useRegions from "../regions/useSortedRegions";
import {
  PersistEMAction,
  PERSIST_EM,
  SET_EM_CONFLUENCE_ACCESS,
  SET_EM_JIRA_ACCESS
} from "./EMsTypes";
import { EMUtilizationForm } from "./EMUtilizationForm";
import { useAllEMs } from "./useAllEMs";
import { useEMSkills } from "./useEMSkills";

const schema = yup.object().shape({
  emails: yup.array().optional(),
  firstName: yup.string().required(),
  harvestId: yup.string().optional(),
  id: yup.string().optional(),
  jiraAccountId: yup.string().optional(),
  jiraLabel: yup.string().optional(),
  lastName: yup.string().required(),
  offboardDate: yup.date().optional().nullable(),
  region: yup.string().optional(),
  role: yup.string().optional(),
  staffingCalendarId: yup.string().optional(),
  staffingCalendarTimeOff: yup.object().optional(),
  utilization: yup.array().optional(),
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
  },
}));

export function EMForm() {
  const [now] = useState(new Date());
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const synching = useSelector((state: AppState) => state.ems.synching);
  const all = useAllEMs();
  const regions = useSelector((state: AppState) => state.regions.items);
  const sortedRegions = useRegions();
  const params: { [key: string]: string } = useParams();
  const [em, setManager] = useState(new EMHandle({} as any) as EMHandle);
  const [loading, setLoading] = useState(true);
  const form = useForm({ resolver: yupResolver(schema) });
  const skills = useEMSkills();

  useEffect(() => {
    form.register("lead");
    form.setValue("lead", em.lead || "");
    form.register("region");
    form.setValue("region", em.region);
    form.register("role");
    form.setValue("role", em.role);
    form.register("skills");
    form.setValue("skills", em.skills);
    form.register("utilization");
    form.setValue("utilization", em.utilization);
    form.register("timeOff");
    form.setValue("timeOff", em.timeOff);
  }, [form, em]);

  function onSubmit(data: any): void {
    const offboardDate: Date | undefined = data.offboardDate;
    const action: PersistEMAction = {
      type: PERSIST_EM,
      item: {
        emails: (data.emails as string[] || []).map(email => email.toLowerCase()),
        firstName: data.firstName,
        harvestId: data.harvestId,
        id: data.id,
        jiraAccountId: data.jiraAccountId,
        jiraLabel: data.jiraLabel,
        lastName: data.lastName,
        lead: data.lead,
        microsoftShiftsTimeOff: em.microsoftShiftsTimeOff || {},
        offboardDate: offboardDate
          ? {
              year: offboardDate.getFullYear(),
              month: offboardDate.getMonth(),
              day: offboardDate.getDate(),
            }
          : undefined,
        region: data.region,
        role: data.role,
        skills: data.skills || [],
        staffingCalendarId: em.staffingCalendarId,
        staffingCalendarTimeOff: em.staffingCalendarTimeOff || {},
        utilization: data.utilization || [],
        timeOff: data.timeOff || [],
      },
    };
    const response: Promise<any> = dispatch(action) as any;
    response
      .then((msg: string) => onClose())
      .catch((error: any) => {
        const action: ReportFeedbackAction = {
          type: REPORT_APP_FEEDBACK,
          feedback: error.message || "",
        };
        dispatch(action);
      });
  }

  function onClose(): void {
    if (history.length > 0) {
      history.goBack();
    } else {
      history.push("/admninistration/customers");
    }
  }

  useEffect(() => {
    const id = params.id;
    let initialItem = all[id];
    if (!id) {
      setLoading(false);
    } else if (initialItem) {
      setLoading(false);
      setManager(initialItem);
    }
  }, [params.id, all]);

  return (
    <Container maxWidth="md">
      {loading ? (
        <CircularProgress />
      ) : (
        <form
          className={classes.root}
          onSubmit={form.handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <input
            type="hidden"
            name="id"
            value={params.id}
            ref={form.register}
          />
          <FormControl fullWidth>
            <Typography variant="h4">Engagement manager form</Typography>
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="First name"
                  name="firstName"
                  defaultValue={em.firstName || ""}
                  error={!!form.errors.firstName}
                  inputRef={form.register}
                  helperText={form.errors.firstName?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Last Name"
                  name="lastName"
                  defaultValue={em.lastName || ""}
                  error={!!form.errors.lastName}
                  inputRef={form.register}
                  helperText={form.errors.lastName?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <EmailInput
                form={form}
                name="emails"
                defaultValue={Array.isArray(em.emails) ? em.emails : []}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: em.hasRackspaceEmail() ? "inherit" : "none",
              }}
            >
              <DispatchActionButton
                title="Grant Jira Access"
                action={SET_EM_JIRA_ACCESS}
                id={em.id}
              />
              <Divider orientation="vertical" />
              <DispatchActionButton
                title="Grant Confluence Access"
                action={SET_EM_CONFLUENCE_ACCESS}
                id={em.id}
              />
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="region-label">Region</InputLabel>
                <Select
                  labelId="region-label"
                  name="role"
                  defaultValue={em.region || ""}
                  onChange={(e) =>
                    form.setValue("region", e.target.value as string)
                  }
                  error={!!form.errors.region}
                  input={<Input />}
                  renderValue={(value) => regions[`${value}`]?.name || ""}
                >
                  {Object.values(sortedRegions).map((region) => (
                    <MenuItem key={region.id} value={region.id}>
                      <ListItemText primary={region.name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{form.errors.region?.message}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  name="role"
                  defaultValue={em.role || null}
                  onChange={(e) =>
                    form.setValue("role", e.target.value as string)
                  }
                  error={!!form.errors.role}
                  input={<Input />}
                  renderValue={(value) => `${value}`}
                >
                  {Object.values(EMRole).map((name) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{form.errors.role?.message}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                name="jiraLabel"
                label="Jira Label"
                form={form}
                defaultValue={new EMHandle(em).getJiraLabel()}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6}>
              <EMSelect
                name="lead"
                form={form}
                label="Lead"
                defaultValue={em.lead}
                emFilter={(em) => em.isLead()}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomDatePicker
                control={form.control}
                record="offboardDate"
                label="Offboard date"
                year={em?.offboardDate?.year}
                month={em?.offboardDate?.month}
                day={em?.offboardDate?.day}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  options={skills}
                  freeSolo
                  defaultValue={em.skills}
                  onChange={(_, value: string[]) =>
                    form.setValue("skills", value)
                  }
                  renderInput={(params) => (
                    <TextField
                      error={!!form.errors.skills}
                      helperText={form.errors.skills?.message}
                      {...params}
                      variant="standard"
                      label="Skills"
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                name="jiraAccountId"
                label="Jira account ID"
                form={form}
                defaultValue={em.jiraAccountId || ""}
                disabled={true}
              />
            </Grid>

            <Grid item xs={4}>
              <CustomTextField
                name="staffingCalendarId"
                label="Staffing calendar id"
                defaultValue={em.staffingCalendarId || ""}
                form={form}
                disabled={!!em.staffingCalendarId}
              />
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  label="Harvest ID"
                  name="harvestId"
                  defaultValue={em.harvestId || ""}
                  error={!!form.errors.harvestId}
                  inputRef={form.register}
                  helperText={form.errors.harvestId?.message}
                  InputProps={{
                    endAdornment: !!em.harvestId ? (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="go"
                          onClick={() =>
                            window.open(
                              `https://sturdycloud.harvestapp.com/team/${em.harvestId}`,
                              "_blank"
                            )
                          }
                        >
                          <ArrowForwardIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography variant="h6" style={{ marginTop: 16 }}>
                  Utilization
                </Typography>
                <Typography variant="caption" style={{ color: "gray" }}>
                  The utilization is valid from the given date until the next
                  utilization entry or until the user is offboarded. Thus,
                  there's no need to create a new entry for each month if the
                  values remain the same. The latest entry will be used to
                  calculate the capacity for subsequent months.
                </Typography>
              </FormControl>
              <FormControl fullWidth>
                <Paper variant="outlined">
                  <EMUtilizationForm
                    defaultValue={em.utilization || []}
                    onChange={(utilization) => {
                      form.setValue("utilization", utilization);
                    }}
                  />
                </Paper>
              </FormControl>
            </Grid>
          </Grid>
          <FormControl fullWidth>
                <Typography variant="h6" style={{ marginTop: 16 }}>
                  Time off
                </Typography>
                <Typography variant="caption" style={{ color: "gray" }}>
                  Note that if the time off is already in the Microsft Shifts 
                  app it must not be added here again.
                </Typography>
              </FormControl>
              <FormControl fullWidth>
                <Paper variant="outlined">
                  <EMTimeOffForm
                    defaultValue={
                      Array.isArray(em.timeOff) ? em.timeOff : []
                    }
                    onChange={(timeOff) => form.setValue("timeOff", timeOff)}
                  />
                </Paper>
              </FormControl>
          <FormControl fullWidth>
            <Typography variant="h6" style={{ marginTop: 16 }}>
              Microsoft Shifts Time Off
            </Typography>
          </FormControl>
          <FormControl fullWidth>
            <Paper variant="outlined">
              <EngineerStaffingCalendarForm
                timeOff={em.microsoftShiftsTimeOff || {}}
              />
            </Paper>
          </FormControl>
          <FormControl fullWidth>
            <Typography variant="h6" style={{ marginTop: 16 }}>
              Staffing calendar Time off
            </Typography>
          </FormControl>
          <FormControl fullWidth>
            <Paper variant="outlined">
              <EngineerStaffingCalendarForm
                timeOff={em.staffingCalendarTimeOff || {}}
              />
            </Paper>
          </FormControl>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <FormControl margin="normal">
              <Button onClick={onClose} disabled={synching}>
                Discard
              </Button>
            </FormControl>
            <FormControl margin="normal">
              <Button
                disabled={synching}
                variant="contained"
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </FormControl>
          </Grid>
        </form>
      )}
    </Container>
  );
}
