import "date-fns";
import { dateReturn } from "utils/dateReturn";
import React, { useState } from "react";
import {
  Container,
  FormControl,
  IconButton,
  Table,
  TableBody,
  TableCell as DefaultTableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { EMTimeOff } from "msd-capacity-planning-model";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(DefaultTableCell);

export function EMTimeOffForm(props: {
  defaultValue: EMTimeOff[];
  onChange: (timeOff: EMTimeOff[]) => void;
}) {
  const [items, setItems] = useState(props.defaultValue);
  const onChange = props.onChange;

  function addItem(): void {
    const date = new Date();
    const newItem: EMTimeOff = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      days: 0,
    };
    const newItems = JSON.parse(JSON.stringify(items));
    newItems.push(newItem);
    setItems(newItems);
    onChange(newItems);
  }

  function updateItem(index: number, item: EMTimeOff): void {
    const newItems = JSON.parse(JSON.stringify(items));
    newItems[index] = item;
    setItems(newItems);
    onChange(newItems);
  }

  function deleteItem(index: number): void {
    const newDemand = JSON.parse(JSON.stringify(items));
    newDemand.splice(index, 1);
    setItems(newDemand);
    onChange(newDemand);
  }

  return (
    <Container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Month</TableCell>
              <TableCell>Days</TableCell>
              <TableCell align="right">
                <Tooltip title="New item">
                  <IconButton onClick={addItem}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={`item-${index}`}>
                <TableCell>
                  <FormControl fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        margin="none"
                        format="MM/yyyy"
                        views={["year", "month"]}
                        id="date-picker-inline"
                        value={`${item.month + 1}/01/${item.year}`}
                        onChange={(value) => {
                          item.year = dateReturn(value, 'year')
                          item.month = dateReturn(value, 'month')
                          updateItem(index, item);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </TableCell>
                <TableCell align="right">
                  <FormControl fullWidth>
                    <TextField
                      style={{ width: "100%" }}
                      value={item.days || 0}
                      onChange={(e) => {
                        item.days = parseInt(e.target.value);
                        updateItem(index, item);
                      }}
                    />
                  </FormControl>
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Delete">
                    <IconButton onClick={() => deleteItem(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
